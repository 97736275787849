import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/layout";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import LoadingIndicator from "./components/LoadingIndicator";

// Lazy-loaded pages
const Home = React.lazy(() => import("./pages/home"));
const Services = React.lazy(() => import("./pages/services"));
const Products = React.lazy(() => import("./pages/products"));
const Contact = React.lazy(() => import("./pages/contact"));
const References = React.lazy(() => import("./pages/references"));
const Blog = React.lazy(() => import("./pages/blog"));
const BlogDetail = React.lazy(() => import("./pages/blogDetail"));
const ServicesDevelopment = React.lazy(() =>
  import("./pages/servicesDevelopment")
);
const ServicesSocialMedia = React.lazy(() =>
  import("./pages/servicesSocialMedia")
);
const ServicesConsulting = React.lazy(() =>
  import("./pages/servicesConsulting")
);
const ProductsQdms = React.lazy(() => import("./pages/productsQdms"));
const ProductsEba = React.lazy(() => import("./pages/productsEba"));
const ProductsEnsemble = React.lazy(() => import("./pages/productsEnsemble"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <Suspense fallback={<LoadingIndicator />}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} /> {/* Ana Sayfa */}
                {/* Hizmetler */}
                <Route path="hizmetler">
                  <Route index element={<Services />} />
                  <Route path="web-tasarimi" element={<ServicesDevelopment />} />
                  <Route path="sosyal-medya" element={<ServicesSocialMedia />} />
                  <Route path="yonetim-sistemleri-danismanlik" element={<ServicesConsulting />} />
                </Route>
                {/* Ürünler */}
                <Route path="urunler">
                  <Route index element={<Products />} />
                  <Route path="qdms" element={<ProductsQdms />} />
                  <Route path="eba" element={<ProductsEba />} />
                  <Route path="ensemble" element={<ProductsEnsemble />} />
                </Route>
                {/* Blog */}
                <Route path="blog">
                  <Route index element={<Blog />} />
                  <Route path=":id" element={<BlogDetail />} />
                </Route>
                {/* İletişim ve Referanslar */}
                <Route path="iletisim" element={<Contact />} />
                <Route path="referanslar" element={<References />} />
                {/* 404 */}
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
