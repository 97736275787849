import React from "react";
import { useState } from "react";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false); // Control for About submenu
  const [servicesOpen, setServicesOpen] = useState(false); // Control for Services submenu

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const toggleAbout = () => {
    setAboutOpen(!aboutOpen);
  };

  const toggleServices = () => {
    setServicesOpen(!servicesOpen);
  };

  return (
    <nav className="bg-sidoma-white p-4 shadow-custom sticky top-0 z-50">
      <div className=" px-4 sm:px-6 lg:px-8 ">
        <div className="flex justify-between items-center ">
          {/* Logo */}
          <div className="flex-shrink-0">
            <a href="/" className="text-sidoma-blue text-3xl font-bold font-clashDisplay">
              SİDOMA
            </a>
          </div>

          {/* Menu for larger screens */}
          <div className="hidden lg:flex space-x-4 items-center w-2/4 justify-between font-archivo font-semibold">
            {/* About with sub-links */}
            <div className="relative group">
              <button className="text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-lg transition duration-200 ease-in-out">
                <a href="/hizmetler">Hizmetlerimiz</a>
              </button>
              <div className="absolute hidden group-hover:block bg-sidoma-white mt- rounded-md shadow-custom">
                <a
                  href="/hizmetler/web-tasarimi"
                  className="block px-4 py-2 text-lg text-sidoma-blue hover:bg-sidoma-white-hover rounded transition duration-200 ease-in-out"
                >
                  Web Tasarımı
                </a>
                <a
                  href="/hizmetler/sosyal-medya"
                  className="block px-4 py-2 text-lg text-sidoma-blue hover:bg-sidoma-white-hover transition duration-200 ease-in-out"
                >
                  Sosyal Medya İçerik Üretimi
                </a>
                <a
                  href="/hizmetler/yonetim-sistemleri-danismanlik"
                  className="block px-4 py-2 text-lg text-sidoma-blue hover:bg-sidoma-white-hover rounded transition duration-200 ease-in-out"
                >
                  Yönetim Sistemleri Danışmanlığı
                </a>
              </div>
            </div>

            {/* Services with sub-links */}
            <div className="relative group">
              <button className="text-sidoma-blue hover:bg-sidoma-white-hover transition duration-200 ease-in-out px-3 py-2 rounded-md text-lg">
                <a href="/urunler">Ürünlerimiz</a>
              </button>
              <div className="absolute hidden group-hover:block bg-sidoma-white shadow-custom rounded-md ">
                <a
                  href="/urunler/qdms"
                  className="block px-4 py-2 text-lg text-sidoma-blue hover:bg-sidoma-white-hover transition duration-200 ease-in-out rounded"
                >
                  QDMS
                </a>
                <a
                  href="/urunler/ensemble"
                  className="block px-4 py-2 text-lg text-sidoma-blue hover:bg-sidoma-white-hover transition duration-200 ease-in-out"
                >
                  Ensemble
                </a>
                <a
                  href="/urunler/eba"
                  className="block px-4 py-2 text-lg text-sidoma-blue hover:bg-sidoma-white-hover rounded transition duration-200 ease-in-out"
                >
                  Eba
                </a>
              </div>
            </div>
            <a
              href="/referanslar"
              className="text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-lg transition duration-200 ease-in-out"
            >
              Referanslarımız
            </a>

            <a
              href="/blog"
              className="text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-lg transition duration-200 ease-in-out"
            >
              Blog
            </a>
            <a
              href="/iletisim"
              className="text-sidoma-white hover:bg-red-300 px-6 py-2 rounded-md text-lg bg-red-400 transition duration-200 ease-in-out"
            >
              İletişim
            </a>

          </div>

          {/* tr-en dil yeri */}
          <div className="flex-shrink-0 hover:bg-sidoma-white-hover rounded p-1 hidden lg:block relative group font-archivo font-semibold transition duration-200 ease-in-out lg:ml-12">
            {/* <button
              className="text-sidoma-blue text-l font-bold lg:flex"
            >
              TR
            </button>
            <div className="absolute hidden group-hover:block bg-sidoma-white shadow-custom rounded-md ">
                <a
                  href="/services/design"
                  className="block py-2 px-3 text-l text-sidoma-blue hover:bg-sidoma-white-hover rounded transition duration-200 ease-in-out"
                >
                  TR
                </a>
                <a
                  href="/services/development"
                  className="block py-2 px-3 text-l text-sidoma-blue hover:bg-sidoma-white-hover transition duration-200 ease-in-out"
                >
                  EN
                </a>
                
            </div> */}
          </div>

          {/* Hamburger Icon for smaller screens */}
          <div className="lg:hidden flex items-center gap-3">
            {/* <div className="flex-shrink-0 hover:bg-sidoma-white-hover rounded p-1 group relative">
              <a
                href="/"
                className="text-sidoma-blue text-l font-bold  md:flex relative group"
              >
                TR
              </a>
              <div className="absolute hidden group-hover:block bg-sidoma-white shadow-custom rounded-md ">
                <a
                  href="/services/design"
                  className="block py-2 px-3 text-l text-sidoma-blue hover:bg-sidoma-white-hover rounded"
                >
                  TR
                </a>
                <a
                  href="/services/development"
                  className="block py-2 px-3 text-l text-sidoma-blue hover:bg-sidoma-white-hover rounded"
                >
                  EN
                </a>
                
            </div>
            </div> */}

            <button
              onClick={toggleMenu}
              className="text-sidoma-blue focus:outline-none hover:bg-sidoma-white-hover rounded p-1"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="lg:hidden mt-7">
          <a
            href="/"
            className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base font-medium"
          >
            Anasayfa
          </a>

          {/* Mobile About Menu */}
          <div>
            <button
              onClick={toggleAbout}
              className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base font-medium"
            >
              Hizmetlerimiz
            </button>
            {aboutOpen && (
              <div className="pl-4">
                <a
                  href="/hizmetler/web-tasarimi"
                  className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base"
                >
                  Web Tasarımı
                </a>
                <a
                  href="/hizmetler/sosyal-medya"
                  className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base"
                >
                  Sosyal Medya Danışmanlığı
                </a>
                <a
                  href="/hizmetler/yonetim-sistemleri-danismanlik"
                  className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base"
                >
                  Danışmanlık
                </a>
              </div>
            )}
          </div>

          {/* Mobile Services Menu */}
          <div>
            <button
              onClick={toggleServices}
              className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base font-medium"
            >
              Ürünlerimiz
            </button>
            {servicesOpen && (
              <div className="pl-4">
                <a
                  href="/urunler/qdms"
                  className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base"
                >
                  QDMS
                </a>
                <a
                  href="/urunler/ensemble"
                  className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base"
                >
                  Ensemble
                </a>
                <a
                  href="/urunler/eba"
                  className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base"
                >
                  Eba
                </a>
              </div>
            )}
          </div>
          <a
            href="/referanslar"
            className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base font-medium"
          >
            Referanslarımız
          </a>

          <a
            href="/blog"
            className="block text-sidoma-blue hover:bg-sidoma-white-hover px-3 py-2 rounded-md text-base font-medium"
          >
            Blog
          </a>

          <a
            href="/iletisim"
            className="block text-sidoma-white hover:bg-sidoma-white-hover bg-red-400 px-3 py-2 rounded-md text-base font-medium mt-1"
          >
            Bize Ulaşın
          </a>
        </div>
      )}
    </nav>
  );
};

export default Header;
