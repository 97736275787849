import React from "react";
import Header from "./header";

const LoadingIndicator = () => {
  return (
    <div className="bg-sidoma-white h-screen ">
      <Header />
      <div className=" h-screen flex flex-col justify-center items-center font-clashDisplay">
        <div className="flex flex-col items-center justify-center space-y-6">
          <div className="animate-spin rounded-full h-24 w-24 border-t-8 border-t-indigo-300 border-4 border-transparent"></div>
          <p className="text-2xl font-semibold text-slate-950 animate-pulse">
            Yükleniyor...
          </p>
          <p className="text-lg text-slate-900 opacity-70">
            Lütfen bekleyin, sayfa yükleniyor.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
